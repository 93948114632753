<template>
  <div class="login login-v2">
    <!-- BEGIN login-container -->
    <div class="login-container" style="margin-top: 0">
      <!-- BEGIN login-header -->
      <div class="login-header">
        <div class="brand">
          <div class="d-flex flex-column bd-highlight mb-25 ml-5">
            <div class="p-1 bd-highlight">
              <span>
                <img :src="ImageLogo" height="100" width="150" alt="" />
              </span>
            </div>
            <div class="bd-highlight">
              <b>Multi</b> Impressões
              <small>high technology paper printing</small>
            </div>
          </div>
        </div>
      </div>
      <!-- END login-header -->
    </div>
    <!-- END login-container -->

    <notifications
      group="top-center"
      :duration="5000"
      :width="500"
      animation-name="v-fade-left"
      position="top center"
    />
  </div>
</template>

<style>
.login-header {
  text-align: center; /* Centralize o conteúdo no meio da página */
}

.login {
  overflow: hidden; /* Impede qualquer barra de rolagem dentro do contêiner login */
}
</style>

<script>
import ImageLogo from '../../assets/Logo-Multi-Branco.png'
export default {
  components: {},
  data() {
    return {
      ImageLogo
    }
  }
}
</script>
